import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "karyaIlmiah";
const modelPath = "/karyailmiah/";

class KaryaIlmiah extends ModelBase {
  constructor() {
    let model = {
        id: null,
        judul: "",
        tanggal: null,
        stase: null,
        metode: null,
        diagnosis: null,
        pembimbing_set: [],
        penguji_set: []
    };
    let requiredFields = [
      "judul", "tanggal", "stase", "metode", "diagnosis",
      "pembimbing_set", "penguji_set"
    ];
    super(modelName, model, requiredFields, [], modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.stase = tdata.stase.id;
    tdata.metode = tdata.metode.id;
    tdata.diagnosis = tdata.diagnosis.id;
    return tdata;
  }

  getPayload() {
    let data = super.getPayload();
    data.pembimbing_set = data.pembimbing_set.map(obj => obj.id);
    data.penguji_set = data.penguji_set.map(obj => obj.id);
    return data;
  }
}

export default KaryaIlmiah;
