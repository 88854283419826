<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light is-uppercase">
            {{ headerText }} KEGIATAN ILMIAH
          </p>
        </header>
        <div class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Judul"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.judul }"
            :message="errorMap.judul"
          >
            <b-input
              ref="firstInput"
              v-model="karyaIlmiah.judul"
              required
              @input="validateInput('judul')"
            ></b-input>
          </b-field>

          <b-field
            label="Tanggal"
            class="tgl-field"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.tanggal }"
            :message="errorMap.tanggal"
          >
            <date-picker
              v-model="karyaIlmiah.tanggal"
              @input="validateInput('tanggal')"
            ></date-picker>
          </b-field>

          <slot />
          <!-- untuk diisi dengan field admin atau preceptor -->
          <b-field
            label="Stase"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.stase }"
            :message="errorMap.stase"
          >
            <generic-select
              v-model="karyaIlmiah.stase"
              apiPath="/stase/options/"
              @input="validateInput('stase')"
            ></generic-select>
          </b-field>

          <b-field
            label="Metode"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.metode }"
            :message="errorMap.metode"
          >
            <generic-select
              v-model="karyaIlmiah.metode"
              apiPath="/karyailmiah/metode/"
              @input="validateInput('metode')"
            ></generic-select>
          </b-field>

          <b-field
            label="Diagnosis"
            class="komponen-field"
            custom-class="is-small is-capitalized"
            :type="{ 'is-danger': errorMap.diagnosis }"
            :message="errorMap.diagnosis"
          >
            <generic-autocomplete
              placeholder="Cari sebelum buat baru"
              apiPath="/penyakit/diagnosis/"
              v-model="karyaIlmiah.diagnosis"
              @input="validateInput('diagnosis')"
              @typing="onDiagnosisACTyping"
            ></generic-autocomplete>

            <div class="control">
              <router-link
                :to="{
                  name: 'diagnosis-add',
                  params: { karyaIlmiah: karyaIlmiah },
                }"
              >
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Pembimbing"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.pembimbing_set }"
            :message="errorMap.pembimbing_set"
          >
            <b-taginput
              v-model="karyaIlmiah.pembimbing_set"
              :data="pembimbingTags"
              autocomplete
              field="nama"
              icon="tag"
              @typing="getFilteredPembimbingTags"
              @input="validateInput('pembimbing_set')"
            ></b-taginput>
          </b-field>

          <b-field
            label="Penguji"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.penguji_set }"
            :message="errorMap.penguji_set"
          >
            <b-taginput
              v-model="karyaIlmiah.penguji_set"
              :data="pengujiTags"
              autocomplete
              field="nama"
              icon="tag"
              @typing="getFilteredPengujiTags"
              @input="validateInput('penguji_set')"
            ></b-taginput>
          </b-field>
          <!-- perlu diganti dengan div spacing -->
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
          </div>
          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
              >Simpan</b-button
            >
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import KaryaIlmiah from "../models/cuKaryaIlmiah.js";

export default {
  name: "KaryaIlmiahCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
  },
  data() {
    this.ilmiahMdl = new KaryaIlmiah();
    this.objectMdl = this.ilmiahMdl; // alias yg digunakan di cuMixin
    let obv = this.ilmiahMdl.getObservables();
    obv.konsulenList = [];
    obv.pembimbingTags = [];
    obv.pengujiTags = [];
    obv.diagnosisInput = "";
    return obv;
  },
  computed: {
    ...mapState("karyailmiah", { karyaIlmiahState: "karyaIlmiah" }),
    stase() {
      return this.karyaIlmiah.stase;
    },
  },
  methods: {
    ...mapActions("karyailmiah", ["setKaryaIlmiah"]),
    validateInput(field) {
      this.ilmiahMdl.validate(field);
    },
    onDiagnosisACTyping(value) {
      this.diagnosisInput = value;
    },
    fetchData() {
      if (this.karyaIlmiahState) {
        this.karyaIlmiah = JSON.parse(JSON.stringify(this.karyaIlmiahState));
        if (!this.isCreate) this.setEdited(this.karyaIlmiah["edited"]);

        for (const field of this.ilmiahMdl.getRequiredFields()) {
          if (this.karyaIlmiah[field]) {
            this.validateInput(field);
          }
        }
        this.setKaryaIlmiah(null);
      } else if (!this.isCreate) {
        this.objectMdl.load(this.$route.params.id);
      }
    },
    // TODO: perbaiki!! jangan langsung fetch semua konsulen
    fetchKonsulen() {
      const konsulenURL = `${APP_CONFIG.baseAPIURL}/anggota/preceptor/`;
      axios
        .get(konsulenURL, { params: { kognitif: "Karya Ilmiah" } })
        .then((response) => {
          this.konsulenList = response.data;
        })
        .catch(() => {
          this.konsulenList = [];
        });
    },
    getFilteredPembimbingTags(text) {
      this.pembimbingTags = this.konsulenList.filter((option) => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    getFilteredPengujiTags(text) {
      this.pengujiTags = this.konsulenList.filter((option) => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    onSaved(respObj) {
      if (this.isCreate) {
        this.$router.replace({
          name: "karyailmiah-detail",
          params: { id: respObj.id },
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
  watch: {
    karyaIlmiah: {
      // karya ilmiah berubah, update edited
      handler(newValue, oldValue) {
        if (!this.ilmiahMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
    stase: {
      handler(newValue, oldValue) {
        if (oldValue) {
          this.setEdited(true);
          if (this.karyaIlmiah.pembimbing_set.length > 0)
            this.validateInput("pembimbing_set");
          if (this.karyaIlmiah.penguji_set.length > 0)
            this.validateInput("penguji_set");
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchKonsulen();
  },
};
</script>
